<template>
  <div class="page-content d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-lg-8 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-5 pr-md-0 d-none d-md-block">
              <div class="auth-left-wrapper px-5 py-5">
                <div class="d-flex align-items-start mb-4">
                  <div class="mr-3">
                    <i class="ic-whatsapp" style="font-size: 22px"></i>
                  </div>
                  <div>
                    <h5 class="mb-2">Verified Whatsapp</h5>
                    <p class="text-muted">We help your business get on Whatsapp</p>
                  </div>
                </div>
                <div class="d-flex align-items-start mb-4">
                  <div class="mr-3">
                    <i class="ic-inbox" style="font-size: 22px"></i>
                  </div>
                  <div>
                    <h5 class="mb-2">Inbox for everyone</h5>
                    <p class="text-muted">24/7 support for you and your team, with local pressence in 7 countries</p>
                  </div>
                </div>
                <div class="d-flex align-items-start mb-4">
                  <div class="mr-3">
                    <i class="ic-channel" style="font-size: 22px"></i>
                  </div>
                  <div>
                    <h5 class="mb-2">Channels supported</h5>
                    <p class="">
                      <i class="ic-twitter mr-2"></i>
                      <i class="ic-messenger mr-2"></i>
                      <i class="ic-telegram mr-2"></i>
                      <i class="ic-whatsapp mr-2"></i>
                      <span class="text-muted">and more</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7 pl-md-0">
              <div class="auth-form-wrapper px-4 py-5">
                <h4 class="mb-2">Welcome back!</h4>
                <h5 class="text-muted font-weight-normal mb-4">Log in to your account.</h5>
                <b-alert dismissible variant="danger" :show="alert_opt.show">{{ alert_opt.message }}</b-alert>
                <form class="forms-sample" @submit.prevent="login">
                  <div class="d-flex mt-3">
                    <!-- eslint-disable vue/require-component-is -->
                    <component is="script" src="https://accounts.google.com/gsi/client" async />
                    <div id="g_id_onload"
                      :data-client_id="client_key_google_auth"
                      data-callback="handleCredentialResponse">
                    </div>
                    <div class="g_id_signin" data-type="standard"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY });

export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
  },
  data() {
    return {
      email: '',
      password: '',
      alert_opt: {
        show: false,
        message: '',
      },
      captchaToken: '',
      client_key_google_auth: process.env.VUE_APP_CLIENT_KEY_GOOGLE_AUTH,
    };
  },
  mounted() {
    window.handleCredentialResponse = (response) => {
      this.doLogin(response.credential);
    };
  },
  methods: {
    async login() {
      this.$gAuth.signIn()
        .then((googleUser) => {
          const idToken = googleUser.getAuthResponse().id_token;
          console.log(idToken);
          // Send the token to your backend
          // this.$http.post('/api/auth/google', { idToken });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async doLogin(idToken = null) {
      await this.$recaptchaLoaded();
      this.captchaToken = await this.$recaptcha();
      const loader = this.$loading.show();
      const data = {
        email: this.email,
        password: this.password,
        captcha_token: this.captchaToken,
      };
      if (idToken) {
        delete data.email;
        delete data.password;
        data.token = idToken;
      }
      this.alert_opt.show = false;
      await this.$store.dispatch('backOffice/doLogin', data)
        .then(async (res) => {
          if (!res.error) {
            this.$router.push('/company');
            this.$store.dispatch('ui/clearAlert');
          } else {
            this.alert_opt = {
              show: true,
              message: res.error.message,
            };
          }
        })
        .catch(async () => {
          this.alert_opt = {
            show: true,
            message: this.$t('general.error.general'),
          };
          this.showAlert();
        });

      loader.hide();
    },
    beforeMount() {
      this.$recaptchaInstance.showBadge();
    },
    beforeDestroy() {
      this.$recaptchaInstance.hideBadge();
    },
  },
};
</script>
